<template>
  <div :key="writeKey">
    <br>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" > 
      <table style="width:100%;" :key="menuKey">
        <tr>
          <td v-if="this.api.getCookies('page') === 'admin'" class="div_title">
            <Select v-model="contentType" :options="this.api.contentTypeList" optionLabel="name" placeholder="게시판유형" v-if="this.api.getCookies('id') === '1513898286'"/>
            <FloatLabel style="width:100%;">
              <InputText id="writeTitle" class="write-input" ref="writeTitle" v-model="writeTitle" style="width:100%;"/>
              <label for="writeTitle">제목</label>
            </FloatLabel> 
            <Button @click="toggleHtmlMode" :label="isHtmlMode ? 'TEXT' : 'HTML'" size="small"/>
          </td>
          <td v-else>
            <FloatLabel style="width:100%;">
              <InputText id="writeTitle" class="write-input" ref="writeTitle" v-model="writeTitle" style="width:100%;"/>
              <label for="writeTitle">제목</label>
            </FloatLabel> 
          </td>
        </tr>
      </table>
      <br><br>
      <div class="ld-json-fields" v-if="this.api.getCookies('page') === 'market' || this.contentType.name === 'market'">
        <FloatLabel>
          <InputText id="productName" v-model="productName" />
          <label for="productName">상품명 *</label>
        </FloatLabel> 
        <FloatLabel>
          <InputText id="productMpn" v-model="productMpn" />
          <label for="productMpn">모델번호</label>
        </FloatLabel> 
        <FloatLabel>
          <InputNumber id="productPrice" v-model="productPrice" currency="KOR" size="small" />
          <label for="productPrice">가격 *</label>
        </FloatLabel> 
        <FloatLabel>
          <InputText id="productBrand" v-model="productBrand" />
          <label for="productBrand">브랜드 *</label>
        </FloatLabel> 
      </div>
      <div class="divider" />
      <br>
      <div class="quill-editor-container" ref="editorContainer">
        <QuillEditor :theme="isHtmlMode ? 'bubble' : 'snow'" @ready="onEditorReady" ref="writeContent" v-model:content="postData" contentType="html" :options="editorOptions" style="height: 600px;" placeholder='내용을입력하세요' />
      </div>
      <div class="bottom-wrapper">
        <div class="tag-input">
          <div class="input-container">
            <input v-model="description" :placeholder="descPlaceholder[this.contentType.name] === undefined ? '요약(한 문장)' : descPlaceholder[this.contentType.name]" class="input-tag" />
          </div>
        </div>
        <div class="tag-input">
          <div class="input-container" @click="focusInput" :class="{ 'is-focused': isFocused }">
            <span v-for="(tag, index) in tags" :key="index" class="tag" >
              {{ tag }}
              <button @click.stop="removeTag(index)" class="remove-tag">
                &times;
              </button>
            </span>
            <input class="input-tag" ref="input" v-model="inputValue" @keydown.enter.prevent="addTag" @keydown.backspace="handleBackspace" @focus="isFocused = true" @blur="isFocused = false" placeholder="Enter tags...">
          </div>
        </div>
        <div class="div_center">
          <Button v-if="this.api.getCookies('page') === 'admin'" @click="click({type:'UPSERT_CONTENTS_TMP'})" label="임시저장" raised size="small" />
          <Button @click="click({type:'INSERT_BOARD'})" label="작성완료" raised size="small" />
        </div>
      </div>
    </div>
    <login v-else @login="login" /> 
  </div>
</template>
<script>
import { ref, onMounted, getCurrentInstance, computed, onUnmounted  } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import Quill from 'quill'
import router from './router'
import login from '@/components/common/Login.vue'

// class MutationObserverModule {
//   constructor(quill, options) {
//     this.quill = quill
//     this.options = options
//     this.container = quill.container
//     this.contentChangeCallback = options.contentChangeCallback
    
//     const observer = new MutationObserver(this.handleMutations.bind(this))
//     observer.observe(this.container, { 
//       childList: true, 
//       subtree: true, 
//       characterData: true,
//       attributes: true 
//     })
//   }

//   handleMutations(mutations) {
//     let contentChanged = false
//     let imageAdded = false
//     let linkAdded = false

//     mutations.forEach(mutation => {
//       if (mutation.type === 'childList') {
//         contentChanged = true
//         mutation.addedNodes.forEach(node => {
//           if (node.nodeName === 'IMG') {
//             imageAdded = true
//             this.processImage(node)
//           } else if (node.nodeName === 'A') {
//             linkAdded = true
//             this.processLink(node)
//           }
//         });
//       } else if (mutation.type === 'characterData') {
//         contentChanged = true;
//       } else if (mutation.type === 'attributes') {
//         if (mutation.target.nodeName === 'IMG') {
//           this.processImage(mutation.target)
//         } else if (mutation.target.nodeName === 'A') {
//           this.processLink(mutation.target)
//         }
//       }
//     });

//     if (contentChanged && this.contentChangeCallback) {
//       this.contentChangeCallback({
//         contentChanged,
//         imageAdded,
//         linkAdded,
//         content: this.quill.root.innerHTML
//       })
//     }
//   }

//   processImage(imgNode) {
//     // 이미지 처리 로직
//     console.log('이미지 처리:', imgNode.src)
//     // 예: 이미지 크기 조정, alt 텍스트 확인 등
//   }

//   processLink(linkNode) {
//     // 링크 처리 로직
//     console.log('링크 처리:', linkNode.href)
//     // 예: 외부 링크 확인, noopener 속성 추가 등
//   }
// }

const Size = Quill.import('attributors/style/size')
Size.whitelist = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '36px']
// Quill.register('modules/mutationObserver', MutationObserverModule)
Quill.register(Size, true)

export default {
  emits: ["login"],
  mounted() {
    this.postData = this.editorContent
    this.menuKey++
  },  
  components: {
    QuillEditor, login
  },
  props: {
    page:String,
    country:String,
    fPrice:String,
    idx:String,
    imgUrl:String,
    name:String,
    orderType:String,
    url:String
  },
  computed: {
    editorContent() {
      if(this.country === undefined) return ''
      else 
        return `<p class="ql-align-center"><br></p><p class="ql-align-center">국가 : ${this.country} </p><p class="ql-align-center"><br></p><p class="ql-align-center">현지가 : ${this.fPrice} </p><p class="ql-align-center"><br></p><p class="ql-align-center">대행유형 : ${this.orderType} </p><p class="ql-align-center"><br></p><p class="ql-align-center"><a href="${this.url}" target="_blank">링크</a> </p><p class="ql-align-center"><br></p><p class="ql-align-center">상품명 : ${this.name}</p><br><br>`
    },
    showLdJsonFields() {
      return this.api.getCookies('page') === 'market'
    }
  },
  data() {
    return {
      postData:'',
      menuKey:-1,
      description:undefined,
      imgList:[],
      writeKey:-1,
      writeTitle:'',
      contentType:{},

      productName: '',
      productMpn: '',
      productBrand: '',
      productPrice: undefined,
      // productReviewRating: null,
      // productReviewAuthor: '',
      // productAggregateRating: null,
      // productReviewCount: null,
      // productOfferCount: null,
      // productLowPrice: null,
      // productHighPrice: null,
      // productPriceCurrency: '',
      
    }
  },
  setup() {
    const writeContent = ref(null)
    const editorContainer = ref(null)
    const toolbar = ref(null)
    const isToolbarFixed = ref(false)
    const descPlaceholder = ref({
      market:'상품에 대한 간략 설명(한문장)',
      review:'상품에 대한 설명 or GEEK9 후기(한문장)',
      blog:'글에 대한 간략 요약(한문장)',
      hot:'실시간 인기 키워드/상품에 대한 요약(한문장)',
      logistics:'해외물류업무에 대한 설명(한문장)',
    })
    const updateToolbarPosition = () => {
      if (!toolbar.value || !editorContainer.value) return

      const headerHeight = 165
      const containerRect = editorContainer.value.getBoundingClientRect()
      const toolbarRect = toolbar.value.getBoundingClientRect()
      toolbar.value.style.position = 'static'
      toolbar.value.style.top = 'auto'
      toolbar.value.style.width = 'auto'
      editorContainer.value.style.paddingTop = '0'

      if (containerRect.top <= headerHeight && !isToolbarFixed.value) {
        toolbar.value.style.position = 'fixed'
        toolbar.value.style.top = `${headerHeight}px`
        toolbar.value.style.width = `${containerRect.width}px`
        editorContainer.value.style.paddingTop = `${toolbarRect.height}px`
      }
    }

    const tags = ref([])
    const inputValue = ref('')
    const input = ref(null)
    const isFocused = ref(false)
    const isHtmlMode = ref(false)
    const quillInstance = ref(null)
    const { proxy } = getCurrentInstance()
    // const imageList = ref([])

    // const handleContentChange = ({ contentChanged, imageAdded, linkAdded, content }) => {
    //   if (contentChanged) {
    //     postData.value = content
    //   }
    //   if (imageAdded) {
    //     // 새 이미지 URL을 imageList에 추가
    //     const newImages = extractNewImageUrls(content, imageList.value)
    //     imageList.value = [...imageList.value, ...newImages]
    //   }
    //   if (linkAdded) {
    //     // 필요한 경우 링크 관련 처리
    //     console.log('새 링크가 추가되었습니다.')
    //   }
    // }

    // const extractNewImageUrls = (content, existingUrls) => {
    //   const parser = new DOMParser()
    //   const doc = parser.parseFromString(content, 'text/html')
    //   const images = doc.querySelectorAll('img')
    //   return Array.from(images)
    //     .map(img => img.src)
    //     .filter(src => !existingUrls.includes(src))
    // }

    const editorOptions = computed(() => ({
      theme: isHtmlMode.value ? 'bubble' : 'snow',
      modules: {
        // mutationObserver: {
        //   contentChangeCallback: handleContentChange
        // },
        toolbar: isHtmlMode.value ? false : {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['image', 'imageUrl', 'link', 'video'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': Size.whitelist }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }]
          ],
          handlers: {
            imageUrl: imageUrlHandler,
            image: imageHandler
          }
        }
      }
    }))
    const imageUrlHandler = () => {
      const url = prompt('이미지 URL을 입력하세요:')
      if (url) {
        const altText = prompt('이미지에 대한 설명을 입력하세요:') || ''
        insertImage(url, altText)
      }
    }

    const insertImage = (url, altText) => {
      const quill = quillInstance.value
      const range = quill.getSelection(true)
      
      quill.insertEmbed(range.index, 'image', url, Quill.sources.USER)
      quill.setSelection(range.index + 1, Quill.sources.SILENT)
      
      // alt 텍스트 설정
      setTimeout(() => {
        const img = quill.container.querySelector(`img[src="${url}"]`)
        if (img) {
          img.setAttribute('alt', altText)
        }
      }, 0)
    }
    const toggleHtmlMode = () => {
      isHtmlMode.value = !isHtmlMode.value
      if (quillInstance.value) {
        if (isHtmlMode.value) quillInstance.value.setText(quillInstance.value.root.innerHTML)
        else quillInstance.value.root.innerHTML = quillInstance.value.getText()
      }
    }
    const imageHandler = () => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        if (!file) return

        // Alt 텍스트 먼저 입력 받기
        const altText = prompt('이미지에 대한 설명을 입력하세요:') || ''
        console.log('altText')
        console.log(altText)
        if(altText === undefined || altText === '') {
          alert('이미지에 대한 설명을 입력해주셔야 됩니다.')
          return
        } else {
          const formData = new FormData()
          formData.append("file", file)
          formData.append('service', 'geek9')
          formData.append('pbType', 'write')
          formData.append('type', 'UPLOAD_IMG')
  
          try {
            const res = await proxy.api.axiosGql('UPLOAD_IMG', formData)
            const imgUrl = proxy.api.WSRV_POCKETBASE_URL + res.data
            proxy.imgList.push(imgUrl)
  
            const quill = writeContent.value.getQuill()
            const range = quill.getSelection(true)
  
            // 이미지 삽입 전 줄바꿈
            quill.insertText(range.index, '\n', Quill.sources.USER)
            quill.setSelection(range.index + 1, Quill.sources.SILENT)
  
            // 이미지 삽입
            quill.insertEmbed(range.index + 1, 'image', imgUrl, Quill.sources.USER)
  
            // 이미지 삽입 후 줄바꿈
            quill.insertText(range.index + 2, '\n', Quill.sources.USER)
            quill.setSelection(range.index + 3, Quill.sources.SILENT)
  
            // Alt 텍스트 설정
            const img = quill.container.querySelector(`img[src="${imgUrl}"]`)
            if (img) img.setAttribute('alt', altText)
          } catch (error) {
            console.error('이미지 업로드 실패:', error)
            alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.')
          }
        }
      }
    }
    const onEditorReady = (quill) => {
      quillInstance.value = quill
      // quillInstance.value.getModule('mutationObserver')
      toolbar.value = editorContainer.value.querySelector('.ql-toolbar')
      const imageUrlBtn = document.querySelector('.ql-imageUrl')
      const svgIcon = `<svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m807.186 686.592 272.864 272.864H0v112.94h1080.05l-272.864 272.978 79.736 79.849 409.296-409.183-409.296-409.184-79.736 79.736ZM1870.419 434.69l-329.221-329.11C1509.688 74.07 1465.979 56 1421.48 56H451.773v730.612h112.94V168.941h790.584v451.762h451.762v1129.405H564.714v-508.233h-112.94v621.173H1920V554.52c0-45.176-17.619-87.754-49.58-119.83Zm-402.181-242.37 315.443 315.442h-315.443V192.319Z" fill-rule="evenodd"></path> </g></svg>`
      if (imageUrlBtn) {
        imageUrlBtn.innerHTML = svgIcon
        imageUrlBtn.title = '링크사진넣기'
      }
      const sizeSelector = document.querySelector('.ql-size')
      if (sizeSelector) {
        sizeSelector.title = '글자크기'
        const options = sizeSelector.querySelectorAll(`.ql-picker-item`)
        
        for(let i=0; i<options.length; i++) {
          let option = options[i]
          const size = Size.whitelist[i]
          if (option) option.textContent = ' - ' + size
        }
      }

      quillInstance.value.on('editor-change', (eventName, ...args) => {
        if (eventName === 'selection-change') {
          const [range] = args
          if (range) {
            const format = quillInstance.value.getFormat(range)
            if (format.size) sizeSelector.value = format.size
            else sizeSelector.value = ''
          }
        }
      })

      const linkButton = editorContainer.value.querySelector('.ql-link')
      if (linkButton) linkButton.title = '선택된 글자 링크 걸기'

      if (toolbar.value) {
        toolbar.value.style.position = 'static'
        toolbar.value.style.top = 'auto'
        toolbar.value.style.width = 'auto'
      }
    }

    const addTag = () => {
      const trimmedValue = inputValue.value.trim()
      if (trimmedValue && !tags.value.includes(trimmedValue)) {
        tags.value.push(trimmedValue)
        inputValue.value = ''
      }
    }

    const removeTag = (index) => {
      tags.value.splice(index, 1)
    }

    const handleBackspace = () => {
      if (inputValue.value === '' && tags.value.length > 0) {
        removeTag(tags.value.length - 1)
      }
    }

    const focusInput = () => {
      input.value.focus()
    }

    onMounted(() => {
      if(input.value) {
        input.value.focus()
        window.addEventListener('scroll', updateToolbarPosition)
        window.addEventListener('resize', updateToolbarPosition)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateToolbarPosition)
      window.removeEventListener('resize', updateToolbarPosition)
    })

    // const postData = ref("")

    return {
      removeTag, addTag, tags, input, isFocused, focusInput, handleBackspace, inputValue, editorOptions, quillInstance
      ,toggleHtmlMode, isHtmlMode, onEditorReady, editorContainer, writeContent, updateToolbarPosition, descPlaceholder
      // imageList
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    async click(param) {
      let type = param.type
      if(type === 'UPSERT_CONTENTS_TMP') {
        if(confirm("임시저장할까요?")) {
          this.deleteUnusedImg()
          const id = this.api.getCookies('id')
          const contentJson = {
            desc:this.description,
            name:this.productName,
            sku:this.productMpn,
            brand:this.productBrand,
            sellPrice:this.productPrice,
            content:this.postData,
            title:this.writeTitle,
            profileImgUrl:window.$cookies.get('img'),
            id:id,
            type:this.contentType.name,
            useYn:1,
            imgList:this.imgList
          }
          const content = JSON.stringify(contentJson, null, 2)
          const formData = new FormData()
          formData.append('id', id)
          formData.append('type', type)
          formData.append('content', content)
          await this.api.axiosGql(type, formData)
          .then( async (res) => {
            if(res.status === 200) {
              alert('저장되었습니다')
            } else alert('다시 시도해주세요')
          })
          .catch(err => {
            console.error("Error:", err)
          })
        }
      } else if(type === 'INSERT_BOARD') {
        if(this.api.getCookies('page') !== 'admin') this.contentType = {name: this.api.getCookies('page')}
        if(confirm("등록할까요?")) {
          if(this.writeTitle === '') alert('제목을 입력하세요')
          else if(this.postData === '') alert('내용을 작성해주세요')
          else if(this.description === undefined || this.description === '') alert('간략 요약을 작성해주세요')
          else if(this.contentType.name === undefined) alert('게시판 유형을 선택해주세요')
          else if(this.tags.length === 0) alert('태그를 추가해주세요')
          else {
            this.deleteUnusedImg()
            const formData = new FormData()
            const id = this.api.getCookies('id')
            if(this.contentType.name === 'market') {
              if(this.imgList.length === 0) alert('상품 사진을 한 장 이상 올려주세요')
              else if(this.productName === '') alert('상품명을 입력해주세요')
              else if(this.productPrice === '') alert('판매가격을 입력해주세요')
              else if(this.productBrand === '') alert('브랜드를 입력해주세요')
              else {
                type = 'INSERT_TRADE'
                
                formData.append('type', type)
                formData.append('desc', this.description)
                formData.append('name', this.productName)
                formData.append('sku', this.productMpn)
                formData.append('brand', this.productBrand)
                formData.append('sellPrice', this.productPrice)
                formData.append('content', this.postData)
                formData.append('title', this.writeTitle)
                formData.append('id', id)
                formData.append('boardType', this.contentType.name)
                formData.append('useYn', 1)
                formData.append('imgList', this.imgList)
                if(id === '1513898286') formData.append('profileImgUrl', 'https://geek9.kr/img/logo_big.e0186dc8.jpg')
                else formData.append('profileImgUrl', window.$cookies.get('img'))
                
                const keyword = await this.api.list2String(this.tags)
                formData.append('keyword', keyword.replace(/"/g, ''))
                
                await this.api.axiosGql(type, formData)
                .then( async (res) => {
                  if(res.status === 200) {
                    alert('등록되었습니다')
                    router.push({ name: this.api.getCookies('page') })
                  } else alert('다시 시도해주세요')
                })
                .catch(err => {
                  console.error("Error:", err)
                })
              }
            } else {
              const keyword = await this.api.list2String(this.tags)
              formData.append('boardType', this.contentType.name)
              formData.append("id", id)
              formData.append('type', type)
              formData.append('title', this.writeTitle)
              formData.append('content', this.postData)
              formData.append('desc', this.description)
              formData.append('keyword', keyword.replace(/"/g, ''))
              formData.append('imgUrl',  await this.api.getFirstImageSrc(this.postData))
              if(id === '1513898286') formData.append('profileImgUrl', 'https://geek9.kr/img/logo_big.e0186dc8.jpg')
              else {
                let profileImg = window.$cookies.get('img')
                if(profileImg === undefined || profileImg === null || profileImg === '' ) profileImg = 'https://geek9.kr/img/logo_big.e0186dc8.jpg'
                formData.append('profileImgUrl', profileImg)
              }

              // console.log('id : ' + id)
              // console.log('type : ' + type)
              // console.log('title : ' + this.writeTitle)
              // console.log('desc : ' + this.description)
              // console.log('keyword : ' + keyword.replace(/"/g, ''))
              // console.log('imgUrl : ' + '')
              // console.log('profileImgUrl : ' + 'https://geek9.kr/img/logo_big.e0186dc8.jpg')
              // console.log('content : ' + this.postData)

              await this.api.axiosGql(type, formData)
              .then( async (res) => {
                if(res.status === 200) {
                  alert('등록되었습니다')
                  router.push({ name: this.api.getCookies('page') })
                } else alert('다시 시도해주세요')
              })
              .catch(err => {
                console.error("Error:", err)
              })
            }
          }
        }
      }
    },
    deleteUnusedImg() {
      for(let i=0; i<this.imgList.length; i++) {
        const deleteImgUrl = this.imgList[i].replace('https:','')
        if(this.postData.indexOf(deleteImgUrl) === -1) {
          this.imgList.splice(i, 1)
          const split = deleteImgUrl.split('/')
          const subType = 'DELETE_IMG'
          const formData = new FormData()
          formData.append("id", split[split.length-2])
          formData.append('type', subType)
          this.api.axiosGql(subType, formData)
          --i
        } 
      }
    },
  }
}
</script>
<style scoped>
.ld-json-fields {
  display: grid;
  gap: 40px;
  margin-bottom: 20px;
}

/* 4열 레이아웃 (큰 화면) */
@media (min-width: 1200px) {
  .ld-json-fields {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* 2열 레이아웃 (중간 화면) */
@media (max-width: 1199px) and (min-width: 768px) {
  .ld-json-fields {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 1열 레이아웃 (작은 화면) */
@media (max-width: 767px) {
  .ld-json-fields {
    grid-template-columns: 1fr;
  }
}

.input-wrapper {
  margin-bottom: 20px;
}

.ld-json-fields :deep(.p-float-label) {
  width: 100%;
}

.ld-json-fields :deep(.p-inputtext),
.ld-json-fields :deep(.p-inputnumber) {
  width: 100%;
}

.ld-json-fields :deep(.p-inputnumber-input) {
  width: 100%;
  padding: 0.5rem;
}

.ld-json-fields :deep(.p-float-label label) {
  background-color: white;
  padding: 0 4px;
  margin-left: -4px;
}

.ld-json-fields :deep(.p-inputnumber) {
  display: flex;
}

.ld-json-fields :deep(.p-inputnumber .p-inputtext) {
  flex: 1;
  border-radius: 4px;
}

.ld-json-fields :deep(.p-inputnumber-button) {
  width: 2rem;
}

.ld-json-fields :deep(.p-float-label input:focus ~ label),
.ld-json-fields :deep(.p-float-label input.p-filled ~ label),
.ld-json-fields :deep(.p-float-label .p-inputwrapper-focus ~ label),
.ld-json-fields :deep(.p-float-label .p-inputwrapper-filled ~ label) {
  top: -0.75rem;
  font-size: 12px;
}
</style>
  